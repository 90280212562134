'use client';

import { Suspense } from 'react';
import { SessionProvider } from 'next-auth/react';

// Components
import { Signin } from '@preventx/diagnode-services';

const Page = () => (
  <Suspense>
    <SessionProvider>
      <Signin />
    </SessionProvider>
  </Suspense>
);

export default Page;
